<template>
<div class="home dirr">
    <div class="news"> 
        <div class="bcon d-none d-sm-flex">
            <div class="newsCon">
                <div class="titleMore">
                    <router-link :to="'/news?lang=' + $i18n.locale" class="tl">{{ $t("newsAct") }}</router-link>
                    <div class="btnCon">
                        <router-link :to="'/news/?&lang=' +$i18n.locale">
                            <v-btn width="200" height="50" class="ma-2 tl" style="align-self:center !important;" color="#145C5E">
                                <p class="btnT tl">{{ $t('allnews') }}</p>
                            </v-btn>
                        </router-link>
                    </div>
                </div>
                <div class="items">
                    <v-layout row>
                        <v-flex xs12 sm6 md8 lg8>
                            <div class="Fgroup">
                                <v-layout row>
                                    <template v-if="impoNewsRes">
                                        <v-flex xs12 sm12 md6 lg6 v-for="(i, x) in impoNewsRes.slice(0,2)" :key="i" style="padding:10px;">
                                            <router-link :to="'/news/' + i.id + '?&lang=' +$i18n.locale" :class="'Fgroup' + x">
                                                <div class="img"> 
                                                    <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                                        :src="Url+'/art_img/thumb_new/'+i.url_image" style="border-radius: 4px;width:100%;height:100%">
                                                    </v-img>
                                                </div>
                                                <div class="details">
                                                    <p class="tl">{{i.cat_title}}</p>
                                                    <p class="tl">{{ i.date|FormatDate }}</p>
                                                    <!-- <p class="tl">{{ i.date }}</p> -->
                                                </div>
                                                <p class="tl ta">{{i.title|subStrtitle}}</p>
                                                <p :class="$i18n.locale=='ar' ? 'txx' : 'tx'" class="ta">{{i.desc|subStrtext}}</p>
                                            </router-link>
                                        </v-flex>
                                    </template>
                                </v-layout>
                            </div>
                        </v-flex>
                        <v-flex xs12 sm6 md4 lg4>
                            <div class="Sgroup">
                                <template v-if="impoNewsRes">
                                    <router-link v-for="i in impoNewsRes.slice(2,6)" :key="i" :to="'/news/' + i.id + '?&lang=' +$i18n.locale">
                                        <div class="img" style="max-height: 121px; ">
                                             <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                                :src="Url+'/art_img/thumb_new/'+i.url_image" style="border-radius: 4px;width:100%;height:100%">
                                            </v-img>
                                        </div>
                                        <div class="details">
                                            <p class="tl ta">{{i.title|subStrtitle2}}</p>
                                            <div class="cat">
                                                <p class="tl">{{i.cat_title}}</p>
                                                <p class="tl">{{ i.date|FormatDate }}</p>
                                            </div>
                                        </div>
                                    </router-link>
                                </template>
                            </div>
                        </v-flex>
                    </v-layout>
                </div>
            </div>
        </div>
        <div class="mcon d-flex d-sm-none">
            <div class="newsCon">
                <div class="titleMore">
                    <router-link :to="'/news?lang=' + $i18n.locale" class="tl">{{ $t("newsAct") }}</router-link>
                </div>
                <div class="items">
                    <v-layout row>
                        <v-flex xs12 sm6 md8 lg8>
                            <div class="Fgroup">
                                <v-layout row>
                                    <template v-if="impoNewsRes">
                                        <v-flex xs12 sm12 md6 lg6 v-for="(i, x) in impoNewsRes.slice(0,1)" :key="i" style="padding:10px;">
                                            <router-link :to="'/news/' + i.id + '?&lang=' +$i18n.locale" :class="'Fgroup' + x">
                                                <div class="img">
                                                    <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                                        :src="Url+'/art_img/art_img_main/'+i.url_image" style="border-radius: 10px; width: 100%; height: 100%;">
                                                    </v-img>
                                                </div>
                                                <div class="details">
                                                    <p class="tl">{{i.cat_title}}</p>
                                                    <p class="tl">{{ i.date|FormatDate }}</p>
                                                </div>
                                                <p class="tl ta">{{i.title|subStrtitle}}</p>
                                                <p :class="$i18n.locale=='ar' ? 'txx' : 'tx'" class="ta">{{i.desc|subStrtext}}</p>
                                            </router-link>
                                        </v-flex>
                                    </template>
                                </v-layout>
                            </div>
                        </v-flex>
                        <v-flex xs12 sm6 md4 lg4>
                            <div class="Sgroup">
                                <template v-if="impoNewsRes">
                                    <router-link v-for="i in impoNewsRes.slice(4,6)" :key="i" :to="'/news/' + i.id + '?&lang=' +$i18n.locale">
                                        <div class="img">
                                            <v-img 
                                            
                                            :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                                        :src="Url+'/art_img/thumb_new/'+i.url_image"
                                            
                                             style="border-radius: 4px;">
                                            </v-img>
                                        </div>
                                        <div class="details">
                                            <p class="tl ta">{{i.title|subStrtitle2}}</p>
                                            <div class="cat d-none d-sm-flex">
                                                <p class="tl">{{i.cat_title}}</p>
                                                <!-- <p class="tl">{{ i.time|FormatDate }}</p> -->
                                                <p class="tl">{{ i.date }}</p>
                                            </div>
                                        </div>
                                    </router-link>
                                </template>
                            </div>
                        </v-flex>
                    </v-layout>
                </div>
            </div>
        </div>
    </div>
    <div class="photo">
        <div class="titleMore">
            <router-link :to="'/photos?lang=' + $i18n.locale" class="tl">{{ $t("photoLibrary") }} </router-link>
            <div class="btnCon d-none d-sm-flex">
                <router-link :to="'/photos/?&lang=' +$i18n.locale">
                    <v-btn width="200" height="50" class="ma-2 tl" style="align-self:center !important;" color="#145C5E">
                        <p class="btnT tl">{{ $t('allPhotos') }}</p>
                    </v-btn>
                </router-link>
            </div>
        </div>
        <div class="items">



            <swiper class="swiper dirr photos_desktop" ref="mySwiper" :options="swiperOptions3">
                <swiper-slide  class="swiper-slide" v-for="silde in 3" :key="silde">
                    <div class="row3 photos"> 
                        <template v-for="index in 3" >
                        
                            <div :class="'col column'+index" :key="index">
                                <template v-for="(i, x) in photoSlice (photosData, index, silde)">

                             

                                    <v-img aspect-ratio="1" :lazy-src="Url+'/libraryImage/small_thumb/'+i.url_image"
                                        :src="Url+'/libraryImage/thumb/'+i.url_image" :class="'p'+x" :key="i"></v-img>
                                </template>
                            </div>

                        </template>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
            </swiper>

            <swiper class="swiper dirr photos_tab d-none" ref="mySwiper" :options="swiperOptions4">
                <swiper-slide  class="swiper-slide" v-for="silde in 5" :key="silde">
                    <div class="row3 photos"> 
                        <template v-for="index in 2" >
                        
                            <div :class="'col column'+index" :key="index">
                                <template v-for="(i, x) in photoSliceTab (photosData, index, silde)">
                                    <v-img aspect-ratio="1" :lazy-src="Url+'/libraryImage/small_thumb/'+i.url_image"
                                        :src="Url+'/libraryImage/thumb/'+i.url_image"  :class="'p'+x" :key="i"></v-img>
                                </template>
                            </div>

                        </template>
                    </div>
                </swiper-slide>
            </swiper>


            <swiper class="swiper dirr photos_mob d-none" ref="mySwiper" :options="swiperOptions4">
                <swiper-slide  class="swiper-slide" v-for="silde in 7" :key="silde">
                    <div class="row3 photos"> 
                
                        <div class="col column1" >
                            <template v-for="(i, x) in photoSliceMob (photosData, 1, silde)">
                                <v-img aspect-ratio="1" :lazy-src="Url+'/libraryImage/small_thumb/'+i.url_image"
                                        :src="Url+'/libraryImage/thumb/'+i.url_image" :class="'p'+x" :key="i"></v-img>
                            </template>
                        </div>

                    </div>
                </swiper-slide>
            </swiper>


        </div>
    </div>
    <div class="qurans">
        <div class="bcon d-none d-sm-flex">
            <div class="quransCon">
                <v-layout row>
                    <v-flex xs12 sm7 md9 lg8>
                        <div class="printed">
                            <router-link :to="'/quran/3?lang=' + $i18n.locale" class="tl">{{ $t("printedQurans") }}</router-link>
                            <div class="items">
                            <v-layout row>
                                <v-flex xs12 sm12 md6 lg6 v-for="(i, x) in quraansRes" :key="i">
                                <router-link :to="'/news/' + i.id + '?&lang=' +$i18n.locale" :class="'printedA' + x">
                                    <div class="img">
                                        <!-- <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+test_img(i.url_image)"
                                            :src="'https://alkafeel.net/news/mcroped96/275/'+test_img(i.url_image)" style="border-radius: 4px;">
                                        </v-img> -->
                                        <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                            :src="Url+'/art_img/thumb_new/'+i.url_image" style="border-radius: 4px; width: 100% !important; height: 100%;">
                                        </v-img>
                                    </div>
                                    <div class="details">
                                        <p class="tl ta">{{i.title|subStrtitle2}}</p>
                                        <div class="cat">
                                            <!-- <p class="tl">{{i.cat_title}}</p> -->
                                            <!-- <p class="tl">{{ i.time|FormatDate }}</p> -->
                                            <p class="tl">{{ i.date|FormatDate }}</p>
                                        </div>
                                    </div>
                                </router-link>
                                </v-flex>
                            </v-layout>
                            </div>
                        </div>
                        <div class="painted">
                            <router-link :to="'/quran/2?lang=' + $i18n.locale" class="tl">{{ $t("Manuscripts") }}</router-link>
                            <div class="items">
                            <v-layout row>
                                <v-flex xs12 sm12 md6 lg6 v-for="i in quraansRes2" :key="i">
                                <router-link :to="'/news/' + i.id + '?&lang=' +$i18n.locale">
                                    <div class="img">
                                        <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                            :src="Url+'/art_img/thumb_new/'+i.url_image" style="border-radius: 4px; width: 100% !important; height: 100%;">
                                        </v-img>
                                    </div>
                                    <div class="details">
                                        <p class="tl ta">{{i.title|subStrtitle2}}</p>
                                        <div class="cat">
                                            <!-- <p class="tl">{{i.cat_title}}</p> -->
                                            <!-- <p class="tl">{{ i.time|FormatDate }}</p> -->
                                            <p class="tl">{{ i.date|FormatDate }}</p>
                                        </div>
                                    </div>
                                </router-link>
                                </v-flex>
                            </v-layout>
                            </div>
                        </div>
                    </v-flex>
                    <v-flex xs12 sm5 md3 lg4>
                        <img class="Quraans_icon" src="/assets/img/Quraans_icon.png" alt="">
                    </v-flex>
                </v-layout>
            </div>
        </div>
        <div class="mcon d-flex d-sm-none">
            <div class="printed">
                <router-link :to="'/quran/3?lang=' + $i18n.locale" class="tl">{{ $t("printedQurans") }}</router-link>
                <img class="Quraans_icon" src="/assets/img/Quraans_icon_mob.png" alt="">
                <div class="items">
                    <div class="Fgroup">
                        <router-link  v-for="(i, x) in quraansRes" :key="i" :to="'/news/' + i.id + '?&lang=' +$i18n.locale" :class="'Fgroup' + x">
                            <div class="img">
                                <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                    :src="Url+'/art_img/thumb_new/'+i.url_image" style="border-radius: 4px; width: 100% !important; height: 100%;">
                                </v-img>
                            </div>
                            <div class="details">
                                <!-- <p class="tl">{{i.cat_title}}</p> -->
                                <!-- <p class="tl">{{ i.time|FormatDate }}</p> -->
                                <p class="tl">{{ i.date|FormatDate }}</p>
                            </div>
                            <p class="tl ta">{{i.title|subStrtitle}}</p>
                            <p :class="$i18n.locale=='ar' ? 'txx' : 'tx'" class="ta">{{i.text|subStrtext}}</p>
                        </router-link>
                    </div>
                    <div class="Sgroup">
                        <router-link v-for="i in quraansRes" :key="i" :to="'/news/' + i.id + '?&lang=' +$i18n.locale">
                            <div class="img">
                                <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                    :src="Url+'/art_img/thumb_new/'+i.url_image" style="border-radius: 4px; width: 100% !important; height: 100%;">
                                </v-img>
                            </div>
                            <div class="details">
                                <p class="tl ta">{{i.title|subStrtitle2}}</p>
                                <div class="cat d-none d-sm-flex">
                                    <!-- <p class="tl">{{i.cat_title}}</p> -->
                                    <!-- <p class="tl">{{ i.time|FormatDate }}</p> -->
                                    <p class="tl">{{ i.date|FormatDate }}</p>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="painted">
                <router-link :to="'/quran/2?lang=' + $i18n.locale" class="tl">{{ $t("Manuscripts") }}</router-link>
                <div class="items">
                    <div class="Fgroup">
                        <router-link  v-for="(i, x) in quraansRes2" :key="i" :to="'/news/' + i.id + '?&lang=' +$i18n.locale" :class="'Fgroup' + x">
                            <div class="img">
                                <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                    :src="Url+'/art_img/thumb_new/'+i.url_image" style="border-radius: 4px; width: 100% !important; height: 100%;">
                                </v-img>
                            </div>
                            <div class="details">
                                <!-- <p class="tl">{{i.cat_title}}</p>
                                <p class="tl">{{ i.time|FormatDate }}</p> -->
                                <p class="tl">{{ i.date|FormatDate }}</p>
                            </div>
                            <p class="tl ta">{{i.title|subStrtitle}}</p>
                            <p :class="$i18n.locale=='ar' ? 'txx' : 'tx'" class="ta">{{i.text|subStrtext}}</p>
                        </router-link>
                    </div>
                    <div class="Sgroup">
                        <router-link v-for="i in quraansRes2" :key="i" :to="'/news/' + i.id + '?&lang=' +$i18n.locale">
                            <div class="img">
                                <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                    :src="Url+'/art_img/thumb_new/'+i.url_image" style="border-radius: 4px; width: 100% !important; height: 100%;">
                                </v-img>
                            </div>
                            <div class="details">
                                <p class="tl ta">{{i.title|subStrtitle2}}</p>
                                <div class="cat d-none d-sm-flex">
                                    <!-- <p class="tl">{{i.cat_title}}</p>
                                    <p class="tl">{{ i.time|FormatDate }}</p> -->
                                    <p class="tl">{{ i.date|FormatDate }}</p>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div> 
    </div>
    <div class="video">
        <div class="bcon d-none d-sm-flex">
            <div class="videoCon">
                <div class="Fitem" v-for="i in videosRes.slice(0,1)" :key="i">
                <router-link :to="'/video/' + i.id + '?&lang=' +$i18n.locale">
                    <v-layout row>
                        <v-flex xs12 sm5 md3 lg3 style="padding: 0 10px;">
                            <router-link class="tl videoLib" :to="'/video?lang=' + $i18n.locale">{{ $t("videoLib") }}</router-link>
                            <p class="tx">{{ i.desc|subStrvidtext }}</p>
                            <router-link :to="'/video/?&lang=' +$i18n.locale" class="more">
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="12" fill="#098486"/>
                                    <path d="M16.5 11.134C17.1667 11.5189 17.1667 12.4811 16.5 12.866L10.5 16.3301C9.83333 16.715 9 16.2339 9 15.4641V8.5359C9 7.7661 9.83333 7.28497 10.5 7.66987L16.5 11.134Z" fill="white"/>
                                    </svg>
                                </span>
                                <p class="tl">{{ $t("moreVid") }}</p>
                            </router-link>
                        </v-flex>
                        <v-flex xs12 sm7 md9 lg9 style="padding: 0 10px;">
                            <div class="img">
                                <v-img :lazy-src="Url+'/imageVidoes/small_thumb/'+i.image_url"
                                    :src="Url+'/imageVidoes/img_main/'+i.image_url" style="border-radius: 4px; width: 100% !important; height: 100%;">
                                </v-img>
                                <div class="bkg"></div>
                                <div class="iconTitle">
                                    <div class="icon">
                                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="32" cy="32" r="32" fill="#098486"/>
                                        <path d="M46.5 31.134C47.1667 31.5189 47.1667 32.4811 46.5 32.866L25.5 44.9904C24.8333 45.3753 24 44.8942 24 44.1244L24 19.8756C24 19.1058 24.8333 18.6247 25.5 19.0096L46.5 31.134Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <p class="tl">{{ i.title }}</p>
                                </div>
                            </div>
                        </v-flex>
                    </v-layout>
                </router-link>
                </div>
                <div class="items">
                    <v-layout row>
                        <v-flex xs12 sm3 md3 lg3 v-for="i in videosRes.slice(1,5)" :key="i" style="padding: 0 10px;" class="item">
                            <router-link :to="'/video/' + i.id + '?&lang=' +$i18n.locale">
                                <div class="img">
                                    <v-img :lazy-src="Url+'/imageVidoes/small_thumb/'+i.image_url"
                                        :src="Url+'/imageVidoes/slid_thumb/'+i.image_url" style="border-radius: 4px; width: 100% !important; height: 100%;">
                                    </v-img>
                                    <div class="icon">
                                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.75">
                                        <circle cx="24" cy="24" r="24" fill="#098486"/>
                                        <path d="M34.5 23.134C35.1667 23.5189 35.1667 24.4811 34.5 24.866L19.5 33.5263C18.8333 33.9112 18 33.4301 18 32.6603V15.3397C18 14.5699 18.8333 14.0888 19.5 14.4737L34.5 23.134Z" fill="white"/>
                                        </g>
                                        </svg>
                                    </div>
                                </div>
                                <p class="tl ta">{{i.title|subStrtitle}}</p>
                                <div class="details">
                                    <!-- <p class="tl">{{i.cat_title}}</p>
                                    <p class="tl">{{ i.time|FormatDate }}</p> -->
                                </div>
                            </router-link>
                        </v-flex>
                    </v-layout>
                </div>
            </div>
        </div>
        <div class="mcon d-flex d-sm-none">
            <p class="tl videoLib">{{ $t("videoLib") }}</p>
            <div class="Fitem" v-for="i in videosRes.slice(0,1)" :key="i">
                <router-link :to="'/video/' + i.id + '?&lang=' +$i18n.locale">
                    <div class="img">
                        <!-- <v-img height="100%" :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image_url"
                            :src="'https://alkafeel.net/news/mcroped96/930/'+i.image_url" style="border-radius: 4px;">
                        </v-img> -->
                        <v-img :lazy-src="Url+'/imageVidoes/small_thumb/'+i.image_url"
                            :src="Url+'/imageVidoes/slid_thumb/'+i.image_url" style="border-radius: 4px; width: 100% !important; height: 100%;">
                        </v-img>
                        <div class="bkg"></div>
                        <div class="iconTitle">
                            <div class="icon">
                                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="32" cy="32" r="32" fill="#098486"/>
                                <path d="M46.5 31.134C47.1667 31.5189 47.1667 32.4811 46.5 32.866L25.5 44.9904C24.8333 45.3753 24 44.8942 24 44.1244L24 19.8756C24 19.1058 24.8333 18.6247 25.5 19.0096L46.5 31.134Z" fill="white"/>
                                </svg>
                            </div>
                            <p class="tl">{{ i.title }}</p>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="slider">
                <div :class="'sbtn lbtn '+ $store.state.direction"></div>
                <swiper class="swiper dirr" ref="mySwiper" :options="swiperOptions">
                    <swiper-slide v-for="i in videosRes.slice(1,5)" :key="i">
                    <router-link :to="'/video/' + i.id + '?&lang=' +$i18n.locale" class="item" >
                        <div class="img_con">
                            <div class="play">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.75">
                                    <circle cx="24" cy="24" r="24" fill="#098486"/>
                                    <path d="M34.5 23.134C35.1667 23.5189 35.1667 24.4811 34.5 24.866L19.5 33.5263C18.8333 33.9112 18 33.4301 18 32.6603V15.3397C18 14.5699 18.8333 14.0888 19.5 14.4737L34.5 23.134Z" fill="white"/>
                                    </g>
                                </svg>
                            </div>
                            <img :src="Url+'/imageVidoes/slid_thumb/' + i.image_url" :alt="i.title"/>
                        </div>
                        <p class="tl ta">{{i.title|subStrtitleslider}}</p>
                        <!-- <div class="details">
                            <p class="tl">{{i.cat_title}}</p>
                            <p class="tl">{{ i.time|FormatDate }}</p>
                        </div> -->
                    </router-link>
                    </swiper-slide>
                </swiper>
                <div :class="'sbtn rbtn '+ $store.state.direction"></div>
            </div>
        </div>
    </div>
    <div class="library">
        <div class="title">
            <router-link :to="'/library?lang=' + $i18n.locale" class="tl">{{ $t("Elibrary") }}</router-link>
        </div>
        <div class="libraryCon">
            <div class="tabs">
                <div class="slider">
                    <div :class="'sbtn lbtn2 d-none '+ $store.state.direction"></div>
                    <swiper class="swiper dirr" ref="mySwiper" :options="swiperOptions2">
                        <swiper-slide v-for="(i,n) in libraryRes" :key="i">
                            <v-btn class="btn" :input-value="n" :class="{ 'greeen white--text':active==n}" @click="showlibrary(i.category_id, n)">
                                <span>
                                    <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.29512 1.04934C3.25138 0.508445 3.71064 0 4.26832 0C6.12722 0.010818 8.008 0.302903 9.70289 1.10343C10.9823 1.69842 12.1085 2.61795 12.9068 3.77547C13.0927 4.01347 13.2567 4.29473 13.2348 4.60846C13.2348 9.77945 13.2348 14.9396 13.2348 20.1106C11.5727 18.3906 9.2655 17.4061 6.90359 17.0491C6.02881 16.8977 5.1431 16.8544 4.26832 16.8328C3.72158 16.8328 3.26232 16.3135 3.30606 15.7726C3.29512 10.8612 3.29512 5.9607 3.29512 1.04934Z" fill="#098486"/>
                                    <path d="M19.752 0.551883C21.0423 0.162436 22.3982 0.000166126 23.7432 0.000166126C24.2899 -0.0106519 24.7601 0.508611 24.7164 1.04951C24.7164 5.96088 24.7164 10.8722 24.7164 15.7728C24.7601 16.3137 24.3009 16.833 23.7541 16.833C21.928 16.8654 20.08 17.1142 18.3633 17.7849C17.0183 18.3042 15.7827 19.0939 14.7876 20.1108C14.7876 14.9398 14.7876 9.77963 14.7876 4.60863C14.7657 4.17591 15.0719 3.85137 15.3015 3.51601C16.3841 2.0664 18.0024 1.07115 19.752 0.551883Z" fill="#098486"/>
                                    <path d="M0.791097 3.451C1.09727 3.40772 1.41438 3.42936 1.73149 3.42936C1.73149 7.54019 1.73149 11.651 1.73149 15.7727C1.70962 16.7355 2.25636 17.6766 3.13114 18.0985C3.85283 18.488 4.70575 18.3473 5.49305 18.4339C7.78936 18.6178 10.1294 19.3318 11.8352 20.9328C8.34703 20.1107 4.68388 20.3919 1.21755 21.1384C0.791097 21.2357 0.320902 21.0194 0.124076 20.6299C-0.0180765 20.3703 0.0147278 20.0674 0.00379305 19.7753C0.00379305 14.6909 0.00379305 9.60642 0.00379305 4.53279C-0.0399461 4.03517 0.299032 3.54836 0.791097 3.451Z" fill="#098486"/>
                                    <path d="M26.2691 3.41869C26.6518 3.44032 27.0673 3.35378 27.4391 3.51605C27.8109 3.68914 28.0296 4.10022 27.9968 4.50049C27.9968 9.70394 27.9968 14.8966 27.9968 20.0892C28.0405 20.6301 27.5594 21.1494 27.0017 21.1385C26.5643 21.1061 26.1379 20.9655 25.7114 20.9005C22.5731 20.3272 19.2927 20.1757 16.1653 20.9114C17.8712 19.3103 20.2221 18.5963 22.5184 18.4124C23.2948 18.3259 24.1477 18.4665 24.8585 18.0771C25.7223 17.6552 26.28 16.7032 26.2581 15.7512C26.28 11.6512 26.2691 7.54034 26.2691 3.41869Z" fill="#098486"/>
                                    </svg>
                                </span>
                                {{i.title}}
                            </v-btn>
                        </swiper-slide>
                    </swiper>
                    <div :class="'sbtn rbtn2 d-none '+ $store.state.direction"></div>
                </div>
            </div>
            <div class="items">
                <div class="bcon d-none d-sm-flex">
                    <v-layout row>

                    

                        <v-flex xs12 sm4 md2 lg2 v-for="i in libraryitemsRes.slice(0,6)" :key="i" style="padding: 0 10px;" class="item">
                            <router-link :to="'/library/' + i.id + '?&lang=' +$i18n.locale">
                                <div class="img">
                                    <v-img height="100%" :lazy-src="Url+'/imageBooks/small_thumb/'+i.image_url"
                                        :src="Url+'/imageBooks/thumb/'+i.image_url" style="">
                                    </v-img>
                                </div>
                                <p class="tl ta">{{i.title|subStrtitlelibrary}}</p>
                            </router-link>
                        </v-flex>
                    </v-layout>
                </div>
                <div class="mcon d-flex d-sm-none">
                    <div class="slider">
                          <carousel-3d :width="224" :height="410" :autoplay="true" :autoplay-timeout="10000" :display="3">
                            <slide v-for="(i, x) in libraryitemsRes.slice(0,6)" :key="x" :index="x" >
                                <router-link :to="'/library/' + i.id + '?&lang=' +$i18n.locale">
                                    <div class="img">
                                        <v-img height="100%" :lazy-src="Url+'/imageBooks/small_thumb/'+i.image_url"
                                        :src="Url+'/imageBooks/thumb/'+i.image_url" style="">
                                        </v-img>
                                    </div>
                                    <p class="tl ta">{{i.title|subStrtitlelibrary}}</p>
                                </router-link>
                            </slide>
                        </carousel-3d>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>


<script>
import Axios from "axios";
import { Swiper, SwiperSlide, directive, Pagination, } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { Carousel3d, Slide } from 'vue-carousel-3d';
export default {
    name: "home",
    data() {
        return {
            impoNewsRes : [],
            quraansRes: [],
            quraansRes2: [],
            videosRes: [],
            libraryRes: [],
            photosData: [],
            libraryitemsRes: [],
            active: 0,
            swiperOptions: {
                // slidesPerView: "auto",
                // spaceBetween:40,
                //loop:true,
                freeMode: true,
                navigation: {
                    nextEl: ".rbtn",
                    prevEl: ".lbtn",
                },
                keyboard: true,
            },
            swiperOptions2: {
                slidesPerView: 'auto',
                spaceBetween: 25,
                keyboard: true,
                //loop: true,
                navigation: {
                    nextEl: ".rbtn2",
                    prevEl: ".lbtn2",
                },
            },
            // photos desktop
            swiperOptions3: { 
                slidesPerView: 'auto',
                spaceBetween: 10,
                keyboard: true,
                pagination: {el: '.swiper-pagination', clickable: true,}
            },
            // photos mobile
            swiperOptions4: { 
                slidesPerView: 'auto',
                spaceBetween: 10,
                keyboard: true,
            },
        }
    },
    components: {
        Swiper, SwiperSlide, Pagination,
        Carousel3d, Slide,
    },
    directives: {
        swiper: directive,
    },
    computed: {
        swiper() {return this.$refs.mySwiper.$swiper},
    },
    mounted() {
    }, 
    created () {
        this.getimpoNews();
    },
    methods:{
        getimpoNews() {
            Axios.get("articles/GetByCat/1/6", {
            })
            .then(res => {
                this.impoNewsRes = res.data.data;
                this.getquraans();
                this.getvideos();
                this.getlibrary();
                this.getPhotos();
                //console.log(res.data);
            })
            .catch(() => {
            });
        },
        getquraans () {
            Axios.get("articles/GetByCat/2/4", {
            })
            .then(res => {
                this.quraansRes = res.data.data;
                //console.log(res.data);
            })
            .catch(() => {
            });
            Axios.get("articles/GetByCat/3/2", {
            })
            .then(res => {
                this.quraansRes2 = res.data.data;
                //console.log(res.data);
            })
            .catch(() => {
            });
        },
        getvideos () {
            Axios.get("videos/getlimit/5", {
            })
            .then(res => {
                this.videosRes = res.data.data;
                //console.log(res.data);
            })
            .catch(() => {
            });
        },
        getlibrary () {
            Axios.get("category_books", {
            })
            .then(res => {
                this.libraryRes = res.data.data;
                this.showlibrary (res.data.data[0].category_id, 0);
                //console.log(res.data.data);
            })
            .catch(() => {
            });
        },
        showlibrary (x, n) {
            this.active = n;
            Axios.get("books/getByCat/"+x+"/6", {})
            .then(res => {
                this.libraryitemsRes = res.data.data;
            })
            .catch(() => {
            });
        },
        getPhotos() {
            // 21 items api
            // Axios.get("https://alkafeel.net/main_bg/data/photo_main.json")
            Axios.get("libraryImage")
            .then(d => {this.photosData = d.data.data;})
        },
        photoSlice (data, index, silde) {
            let start,end,step;
            step = (silde-1)*7;
            if (index === 1) {
                start = step+0;
                end = start+3;
            } else {
                start = step+(index*2)-1;
                end = start+2;
            }
            return data.slice(start, end)
        },
        photoSliceTab (data, index, silde) {
            let start,end,step;
            step = (silde-1)*4;
            start = step+(index*2)-2;
            end = start+2;
            return data.slice(start, end)
        },
        photoSliceMob (data, index, silde) {
            let start,end,step;
            step = (silde-1)*3;
            start = step+(index*2)-2;
            end = start+3;
            return data.slice(start, end)
        },
    },
    filters: {
        subStrtext: function (string) {if (string != null) {return string.substring(0, 150) + '...';}},
        subStrvidtext: function (string) {if (string != null) {return string.substring(0, 200) + '...';}},
        subStrtitle: function (string) {if (string != null) {return string.substring(0, 80) + '...';}},
        subStrtitlelibrary: function (string) {if (string != null) {return string.substring(0, 35) + '...';}},
        subStrtitleslider: function (string) {if (string != null) {return string.substring(0, 70) + '...';}},
        subStrtitle2: function (string) {if (string != null) {return string.substring(0, 70) + '...';}},
        FormatDate: function (unixTime) {
            let d = new Date(unixTime*1000);
            let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
            let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
            return (`${da}-${mo}-${ye}`);
        },    
    },
}
</script>

<style scoped>
    .row3 {
        display: flex;
        flex-wrap: wrap;
        padding: 50px 4px;
    }


    .photos_desktop .photos .column1 .p0 {
        width:41%; height:257px; margin: 0;
    }
    .photos_desktop .photos .column1 .p1 {
        width:56%; height:257px; margin: 0 10px 0 0;
    }
    .photos_desktop .photos .column1 .p2 {
        width:100%; height:333px; margin: 10px 0 0 0;
    }
    .photos_desktop .photos .column2 .p0 {
        width:100%; height:360px; margin-bottom: 10px;
    }
    .photos_desktop .photos .column2 .p1 {
        width:100%; height:230px; 
    }
    .photos_desktop .photos .column3 .p0 {
        width:100%; height:223px; margin-bottom: 10px;
    }
    .photos_desktop .photos .column3 .p1 {
        width:100%; height:367px; 
    }


    /* tab */
    .photos_tab .photos .column1 .p0 {
        width:100%; height:360px; margin-bottom: 10px;
    }
    .photos_tab .photos .column1 .p1 {
        width:100%; height:230px; 
    }
    .photos_tab .photos .column2 .p0 {
        width:100%; height:223px; margin-bottom: 10px;
    }
    .photos_tab .photos .column2 .p1 {
        width:100%; height:367px; 
    }
    .photos_tab .column1, .photos_tab .column2 {
        flex: 48%;
        max-width: 48%;
    }

    /* Moble */
    .photos_mob .photos .column1 .p0 {
        width:41%; height:159px; margin: 0;
    }
    .photos_mob .photos .column1 .p1 {
        width:56%; height:159px; margin: 0 10px 0 0;
    }
    .photos_mob .photos .column1 .p2 {
        width:100%; height:207px; margin: 10px 0 0 0;
    }


    .photos .col {
        margin:5px; padding:0
    }
    .photos_desktop .photos .column1 {
        flex: 50%;
        max-width: 50%;
        display: flex;
        flex-wrap: wrap;
    }

    .photos_desktop .photos .column2 {
        flex: 22%;
        max-width: 22%;
    }

    .photos_desktop .photos .column3 {
        flex: 25%;
        max-width: 25%;
    }

    .photos .col img {
        vertical-align: middle;
    }

    @media (max-width: 1083px) {
        .photos_desktop {
            display: none;
        }
        .photos_tab {
            display: block!important;
        }
    }

    @media (max-width: 578px) {
        .photos_tab {
            display: none!important;
        }
        .photos_mob {
            display: block!important;
        }
        .photos_mob .photos .column1 {
            flex: 100%;
            max-width: 100%;
            display: flex;
            flex-wrap: wrap;
        }
    }
</style>